var generic = generic || {};
var site = site || {};

(function ($) {
  site.gnavEmailSignUpV3 = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $('.js-gnav-email-signup-form', $emailContainerNode);
        var $emailSuccess = $('.js-gnav-email-signup-message-success', $emailContainerNode);
        var $emailError = $('.js-gnav-email-signup-message-error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-gnav-email-signup-terms-conditions', $emailContainerNode);
        var $emailFormFields = $('.gnav-email-signup__field', $emailContainerNode);
        var isOverlayHovered = false;

        $emailContainerNodes.closest('.js-utility-nav-content').addClass('loyalty_email_signup');

        if ($emailContainerNode.hasClass('disabled-overlay')) {
          return;
        }

        $(document).on('welcome15.trigger', function () {
          $emailContainerNode.trigger('click');
        });

        $emailContainerNode.once().on('click', function (event) {
          // If close button is pressed then return and avoid add class active.
          if (event.type === 'click' && $(event.target).hasClass('js-gnav-email-signup-close')) {
            return;
          }
          $(this).addClass('active');
          isOverlayHovered = true;
        });
        $('.js-gnav-account-content')
          .once()
          .on('mouseleave', function (event) {
            /* Avoid mouseout on select input suggestion
          Avoid mouseout on hover input field(Firefox) */
            if (
              event.relatedTarget === null ||
              event.relatedTarget.nodeName.toLowerCase() === 'input'
            ) {
              return;
            }
            // Close the overlay after a time to prevent the overlay from closing too quickly when submitting the form.
            setTimeout(function () {
              if (!isOverlayHovered) {
                $emailContainerNode.removeClass('active');
                $emailForm.removeClass('success-gnav-email-signup');
              }
            }, 7000);
            isOverlayHovered = false;
          });

        // Show terms + conditions
        $mobileNumberInput.on('focus', function () {
          $termsAndConditions.slideDown(300);
        });

        $('.js-gnav-email-signup-close', $emailContainerNode).on('click', function () {
          $emailContainerNode.removeClass('active');
          $emailForm.removeClass('success-gnav-email-signup');
          $termsAndConditions.slideUp(0);
          $emailSuccess.add($emailError).addClass('hidden');
          $emailFormFields.removeClass('error');
        });

        $emailForm.once('js-gnav-email-signup').submit(function (submitEvt) {
          var params = {};

          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailFormFields.removeClass('error');

          // Transform string into array of form elements.
          $emailForm.serializeArray().forEach(function (kv) {
            if (kv.name === 'MOBILE_PHONE') {
              kv.value = kv.value.replace(/[^0-9]+/g, '');
            }
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }
          if ($emailContainerNode.find('input[name="ACCEPTED_PRIVACY_POLICY"]').prop('checked')) {
            params.ACCEPTED_PRIVACY_POLICY = 1;
            params.TERMS = 1;
          }

          // @todo Please, remove this section after SDCOMM-2924 goes to PROD.
          if ($.cookie('csrftoken')) {
            params['_TOKEN'] = $.cookie('csrftoken');
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo
                ? response.userinfo.previously_opted_in_email_promotion
                : '';
              var emailAddress = params.PC_EMAIL_ADDRESS;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else if (typeof emailAddress === 'string' && emailAddress.length > 0) {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                $emailInput.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                $emailForm.addClass('success-gnav-email-signup');
                $emailSuccess.removeClass('hidden');
              }
            },
            onFailure: function (jsonRpcResponse) {
              // Get errors back and build a set of list items to inject into the HTML.
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errorHTML = '';
              var errorHTMLLength = errorObjectsArray.length;

              for (var i = 0; i < errorHTMLLength; i++) {
                errorHTML += '<li>' + errorObjectsArray[i].text + '</li>';
                // Use the jsonRpcResponse tags to pair with input nodes.
                // Error tags are returned with details of which form, field are tagged to that particular error message
                for (var j = 2; j < errorObjectsArray[i].tags.length; j++) {
                  var fieldName = errorObjectsArray[i].tags[j].substr(6).toUpperCase();
                  var $node = $('[name=' + fieldName + ']', $emailForm);

                  $node.addClass('error');
                }
              }
              $emailError.html(errorHTML).removeClass('hidden');
              generic.focusErrors(
                $('.js-gnav-email-signup-message-error'),
                $('.js-email-signup-processed')
              );
            }
          });
        });

        generic.focusErrors($('.js-common-error-messages'), $('.js-password-reset__fieldset'));
      });
    }
  };

  Drupal.behaviors.gnavEmailSignUpV3 = {
    attach: function (context) {
      site.gnavEmailSignUpV3.templateContainer = $('.js-gnav-email-signup-v3', context);
      site.gnavEmailSignUpV3.initEmailSignup();
    }
  };
})(jQuery);
